import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { graphql } from 'gatsby';

import SEO from '../components/Seo';
import Layout from '../components/layout';
import FourOhFour from '../pages/404';

const ContentSection = ({ isOdd, title, content }) => (
  <Row className={`m-section ${isOdd ? 'purple' : 'white'} mt-5 p-4 py-5`}>
    <Col md={7}>
      <h3>{title}</h3>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Col>
  </Row>
);

const MarketingPage = (props) => {
  const data = props.data.wordpressWpMarketingPage;

  if (!data.acf.top_image) {
    return <FourOhFour />;
  }

  return (
    <Layout
      backgroundImage={data.acf.top_image.source_url}
      style={{ minHeight: '68vh' }}
    >
      <SEO title={data.title} />
      <Row className='marketing-title'>
        <Col className='mt-10'>
          <h2 className='px-4'>{data.title}</h2>
        </Col>
      </Row>
      {data.acf.sections.map((sect, ndx) => (
        <ContentSection
          title={sect.title}
          content={sect.content}
          key={ndx}
          isOdd={ndx % 2 === 1}
        />
      ))}
    </Layout>
  );
};

export const pageQuery = graphql`
  query($id: Int!) {
    wordpressWpMarketingPage(wordpress_id: { eq: $id }) {
      title
      acf {
        sections {
          title
          content
        }
        top_image {
          source_url
        }
      }
    }
  }
`;

export default MarketingPage;
